



























  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { AboutModel, ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'productKecelakaanDiri',
    components: {
      comparePersonalAccident: () => import('@/components/formCompare/comparePersonalAccident.vue'),
      productHelp: () => import('@/components/ProductHelp.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class productKecelakaanDiri extends Vue {

    @State('page_content') page_content!: ContentModel[];
    @State('abouts') abouts!: AboutModel[];


    public banerDefault = require('@/assets/images/banner/kecelakaan-diri.jpg');
    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kecelakaan-diri-banner')[0]?.image ?? null;
    }

    public get helpImg(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kecelakaan-diri-help')[0]?.image ?? null;
    }

    public get information() : ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kecelakaan-diri-information')[0]??new ContentModel();
    }

    public get aboutApp(): AboutModel {
      return this.abouts.filter((v) => v.id === 0)[0];
    }

    public get listBannerInfo(): ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.SLIDER && item.name === 'kecelakaan-diri-slider');
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
